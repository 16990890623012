import React, { memo, useCallback, useEffect, useState } from "react";
import api from "Api";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";
import useHandleError from "Utils/handleError";
import LoadingSpinner from "Components/shared/LoadingSpinner";
import { FileUpload } from "Features/admin/shared";
import { TranslationsForm } from "Features/admin/ConfigManagement/shared/translations";

export const updateTemplates = async (
  currentValues = [],
  initialValues = [],
  handleError = () => {}
) => {
  const deletedTemplates = initialValues.filter(
    ({ LanguageCode: initialLanguage, FileName: initialFile }) => {
      const currentTemplate = currentValues.find(
        ({ LanguageCode: currentLanguage }) => initialLanguage === currentLanguage
      );
      return !currentTemplate || (currentTemplate.FileName !== initialFile && currentTemplate.FileName === undefined);
    }
  );
  for (const template of deletedTemplates) {
    try {
      await api.Config.Templates.delete(template.LanguageCode, template.Type);
    } catch (error) {
      handleError(error);
    }
  }
  const modifiedTemplates = currentValues.filter(
    ({ FileName }) => FileName !== undefined && typeof FileName !== "string"
  );
  for (const template of modifiedTemplates) {
    try {
      const formData = new FormData();
      formData.append("LanguageCode", template.LanguageCode);
      formData.append("Type", template.Type);
      formData.append("File", template.FileName);
      await api.Config.Templates.post(formData);
    } catch (error) {
      handleError(error);
    }
  }
};

const Templates = ({
  fieldArrayName = "templates",
  fieldWidth,
  validationEnabled = false,
  templateType
}) => {
  const [currentTemplates, setCurrentTemplates] = useState(null);
  const [loadingInitial, setLoadingInitial] = useState(true);
  const handleError = useHandleError();

  const defaultValue = {
    FileName: undefined,
    Type: templateType
  };

  useEffect(() => {
    const loadTemplates = async () => {
      try {
        const { value: templates } = await api.Config.Templates.getByType([templateType]);
        setCurrentTemplates(templates);
      } catch (error) {
        handleError({ error });
      } finally {
        setLoadingInitial(false);
      }
    };
    loadTemplates();
  }, []);

  const getFormData = (template, languageCode) => {
    const data = new FormData();
    data.append("LanguageCode", languageCode);
    data.append("Type", templateType);
    data.append("File", template);
    return data;
  };

  const testTemplate = useCallback(
    async (
      abortController,
      handleProgress,
      languageCode,
      template
    ) => {
      try {
        const data = typeof template === "string"
          ? await api.Config.Templates.testCurrent(
            languageCode,
            templateType,
            abortController.signal
          )
          : await api.Config.Templates.testNew(
            getFormData(template, languageCode),
            abortController.signal,
            handleProgress
          );
        const link = URL.createObjectURL(data);
        window.open(link, '_blank');
      } catch (error) {
        if (abortController.signal?.aborted) {
          return;
        } else {
          handleError({ error });
        }
      }
    }, []
  );

  const validateTemplate = useCallback(
    (template, values, { pristine, modified }, isDefaultLanguage) => {
      // checking modified as a partial workaround for
      // https://github.com/final-form/react-final-form-arrays/issues/147
      if (pristine || !modified || !isDefaultLanguage) {
        return undefined;
      }
      if (template === undefined) {
        return validationEnabled
          ? i18nextTranslateDynamically(
            i18nextKeys.validatorMessage,
            { field: i18nextTranslate(i18nextKeys.commonTemplate) }
          )
          : undefined;
      }
    }, []
  );

  const validateTemplateAsync = useCallback(
    async (
      abortController,
      handleProgress,
      languageCode,
      template
    ) => {
      try {
        await api.Config.Templates.validate(
          getFormData(template, languageCode),
          abortController.signal,
          handleProgress
        );
        return;
      } catch (error) {
        if (abortController.signal?.aborted) {
          return;
        }
        const message = error.response?.data?.Message;
        if (message) {
          return message;
        } else {
          handleError({ error });
        }
      }
    }, []
  );

  const getTemplateComponent = useCallback((props) => (
    <FileUpload
      accept={{ "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"] }}
      buttonText={i18nextTranslate(i18nextKeys.configPaymentTemplateTest)}
      buttonAction={testTemplate}
      fieldName="FileName"
      validate={validateTemplate}
      validateAsync={validateTemplateAsync}
      fieldWidth={fieldWidth}
      {...props}
    />
  ), [])

  return loadingInitial ? (
    <div className="flex justify-center px-32 py-24">
      <LoadingSpinner />
    </div>
  ) : (
    <TranslationsForm
      defaultValue={defaultValue}
      fieldArrayName={fieldArrayName}
      fields={getTemplateComponent}
      fieldWidth={fieldWidth}
      translations={currentTemplates}
    />
  );
};

export default memo(Templates);
